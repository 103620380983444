#features{
    padding: 85px 0;

    .container{
        display: flex;
        column-gap: 50px;
        flex-direction: row;
        justify-content: space-between;

        .feature{
            width: 100%;
            display: flex;
            row-gap: 20px;
            flex-direction: column;

            h3{
                color: #fff;
                font-size: 30px;
                font-weight: 700;
            }

            hr{
                margin: 0;
                height: 3px;
                width: 40px;
                border: none;
            }

            p{
                color: #fff;
                font-size: 18px;
                font-weight: 400;
            }

        }

        @media(max-width: 991px){
            row-gap: 30px;
            flex-direction: column;
        }

    }

}